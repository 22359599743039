import React from "react"
import { graphql } from "gatsby"

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import CMSLayout from '../components/CMSLayout';
import Sections from '../components/Sections';


import { Heading, Subheading } from '../style/home.style';

export default function ProductsPage({
  data,
  cmsPreview,
}) {
  const { heading, subheading, image, sections, blur } = data.markdownRemark.frontmatter
  const Wrapper = cmsPreview ? CMSLayout : Layout;

  return (
    <Wrapper>
      <Hero image={image} blur={blur}>
        <Heading>{heading}</Heading>
        <Subheading>{subheading}</Subheading>
      </Hero>
      <Sections sections={sections} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { template: { eq: "products" } }) {
      frontmatter {
        title
        heading
        subheading
        sections {
          id
          reverse
          centered
          background
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          imageShadow
          content
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blur
      }
    }
  }
`
